import Link from "next/link"
import Slider from "react-slick"
import Image from 'next/image';

const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    autoplay: true,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
            }
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            }
        },
    ]
}

export default function Services() {
    return (
        <>
            <section className="services-area services-bg position-relative">
                <Image
                    src="/assets/img/bg/services_bg.webp"
                    alt="bg"
                    fill
                    objectFit="cover"
                    quality={100}
                />
                <div className="container">
                    <div className="row justify-content-center position-relative">
                        <div className="col-xl-10 col-lg-10">
                            <div className="section-title white-title text-center mb-50 tg-heading-subheading animation-style2">
                                <span className="sub-title tg-element-title">Our Dedicated Services</span>
                                <h2 className="title tg-element-title">Spotlight Some Most <br /> Important Services We Have</h2>
                                <p className="p-break p-break-center">
                                    OHC Medicinal Chemical Trading, your reliable partner in the global trading of active pharmaceutical ingredients (APIs). With our extensive network of trusted suppliers and our commitment to quality, we provide pharmaceutical companies with the essential ingredients they need to develop safe and effective medications. Explore our wide range of APIs & intermediates and discover how we can support your pharmaceutical manufacturing needs.
                                </p>
                            </div>
                        </div>
                    </div>
                    <Slider {...settings} className="row services-active">
                        <div className="col-lg-4">
                            <div className="services-item">
                                <div className="services-content">
                                    <div className="content-top">
                                        <div className="icon">
                                            <i className="fab fa-hubspot fa-rotate-180" />
                                        </div>
                                        <h2 className="title">Diverse Therapeutic Categories</h2>
                                    </div>
                                    <div className="services-thumb">
                                        <Image
                                            src="/assets/img/images/services_slider/therapeutic_categories.webp"
                                            alt="Diverse representation"
                                            width={317}
                                            height={211}
                                            layout="responsive"
                                        />
                                        <Link prefetch={false} href="/services" className="btn transparent-btn">Show More Details</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                      
                    
                        <div className="col-lg-4">
                            <div className="services-item">
                                <div className="services-content">
                                    <div className="content-top">
                                        <div className="icon">
                                            <i className="fab fa-hubspot fa-rotate-180" />
                                        </div>
                                        <h2 className="title">Continuous Product Updates</h2>
                                    </div>
                                    <div className="services-thumb">
                                        <Image
                                            src="/assets/img/images/services_slider/product_update.webp"
                                            alt="Continuous product update illustration"
                                            width={317}
                                            height={211}
                                            layout="responsive"
                                        />
                                        <Link prefetch={false} href="/services" className="btn transparent-btn">Show More Details</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="services-item">
                                <div className="services-content">
                                    <div className="content-top">
                                        <div className="icon">
                                            <i className="fab fa-hubspot fa-rotate-180" />
                                        </div>
                                        <h2 className="title">Extensive APIs Portfolio</h2>
                                    </div>
                                    <div className="services-thumb">
                                        <Image
                                            src="/assets/img/images/services_slider/api_portfolio.webp"
                                            alt="API portfolio graphic"
                                            width={317}
                                            height={211}
                                            layout="responsive"
                                        />
                                        <Link prefetch={false} href="/services" className="btn transparent-btn">Show More Details</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="services-item">
                                <div className="services-content">
                                    <div className="content-top">
                                        <div className="icon">
                                            <i className="fab fa-hubspot fa-rotate-180" />
                                        </div>
                                        <h2 className="title">Access to Innovative APIs</h2>
                                    </div>
                                    <div className="services-thumb">
                                        <Image
                                            src="/assets/img/images/services_slider/innovative_api.webp"
                                            alt="Innovative API concept image"
                                            width={317}
                                            height={317}
                                            layout="responsive"
                                        />
                                        <Link prefetch={false} href="/services" className="btn transparent-btn">Show More Details</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
            </section>
        </>
    )
}
