import Layout from "@/components/layout/Layout";
import About from "@/components/sections/home5/About";
import Contact from "@/components/sections/home5/Contact";
import Faq from "@/components/sections/home5/Faq";
import Services from "@/components/sections/home5/Services";
import Slider from "@/components/sections/home5/Slider";


export default function Home5() {

  return (
    <>
      <Layout headerStyle={4} footerStyle={3}>
        <Slider />
        <About />
        <Services />
        <Faq />
        <Contact />
      </Layout>
    </>
  );
}

