import React, { useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import Image from 'next/image';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';


export default function Contact() {
    // State to keep track of form input
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
        company_name: ''
    });

    // State to handle response message
    const [responseMessage, setResponseMessage] = useState('');
    const [showForm, setShowForm] = useState(true);

    // Function to handle input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };


    // Function to handle form submission
    const handleSubmit = async (e) => {

        try {
            e.preventDefault();
            // Making a post request to the API
            const response = await axios.post('https://ohcchemicals.pythonanywhere.com/api/contact/', formData);

            // Set success message
            setResponseMessage({ type: 'success', message: 'Your message has been sent successfully!' });
            setShowForm(false);
            setFormData({
                name: '',
                email: '',
                phone: '',
                subject: '',
                message: '',
                company_name: '',
            });
            setTimeout(() => {
                setShowForm(true);
                setResponseMessage(null);  // Clear the response message to hide it.
            }, 3000);  // 5 seconds
            // You can also use the response object for more detailed feedback
        } catch (error) {
            // Set error message
            setResponseMessage({ type: 'error', message: 'An error occurred while sending your message.' });
            setShowForm(false);
            setTimeout(() => {
                setShowForm(true);
                setResponseMessage(null);
            }, 3000);
        }
    };

    const renderResponseMessage = () => {
        if (!responseMessage) return null;

        const messageStyle = responseMessage.type === 'success' ? { color: 'green', textAlign: 'center' } : { color: 'red', textAlign: 'center' };
    
        return (
            <div style={messageStyle}>
                {responseMessage.type === 'success' ? 
                    <FontAwesomeIcon icon={faCheckCircle} size="3x" style={{ display: 'block', marginBottom: '20px', margin: '0 auto'}} /> :
                    <FontAwesomeIcon icon={faExclamationTriangle} size="3x" style={{ display: 'block', marginBottom: '20px', margin: '0 auto' }} />
                }
                {responseMessage.message}
            </div>
        );
    };

    return (
        <>
            <section className="contact-area contact-bg position-relative" >
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5">
                            <div className="contact-content">
                                <div className="section-title mb-30 tg-heading-subheading animation-style2">
                                    <span className="sub-title tg-element-title">GET IN TOUCH</span>
                                    <h2 className="title tg-element-title">We Are Connected To Help You!</h2>
                                </div>
                                <p className='p-break'>At OHC Medicinal Chemical Trading, we believe in more than just transactions; we believe in building relationships. Our dedicated team is here to support your business every step of the way.</p>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="contact-form">
                            {showForm ? (
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="form-grp">
                                                <input 
                                                    type="text"
                                                    name="name"
                                                    placeholder="Contact Name *"
                                                    value={formData.name}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-grp">
                                                <input
                                                    type="text"
                                                    name="company_name"
                                                    placeholder="Company Name *"
                                                    value={formData.company_name}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-grp">
                                                <input
                                                    type="email"
                                                    name="email"
                                                    placeholder="E-mail *"
                                                    value={formData.email}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-grp">
                                                <input
                                                    type="text"
                                                    name="subject"
                                                    placeholder="Subject *"
                                                    value={formData.subject}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-grp">
                                                <PhoneInput
                                                    autoFocus={false}
                                                    country={"ae"}
                                                    enableSearch={true}
                                                    excludeCountries={["il"]}
                                                    placeholder="Enter phone number"
                                                    value={formData.phone}
                                                    onChange={phone => setFormData(prev => ({ ...prev, phone }))}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-grp">
                                                <textarea
                                                    name="message"
                                                    placeholder="Comments *"
                                                    value={formData.message}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <button type="submit">Submit Now</button>
                                        </div>
                                    </div>
                                </form>
                                ) : renderResponseMessage()}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="contact-shape">
                    <Image
                        width={60}
                        height={59}
                        src="/assets/img/images/contact_shape.webp" // Use a relative URL or an absolute URL for an external image
                        alt="circle_shape"
                        layout="responsive"
                        placeholder="circle_shape" // Optional blur-up while loading
                        blurDataURL="/assets/img/images/contact_shape.webp" // Provide a low-res version or a data URL for the blur effect
                    />
                </div>
            </section>
        </>
    )
}
