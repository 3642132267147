import Image from 'next/image';
import dynamic from 'next/dynamic';

const DynamicAccordion = dynamic(() => import('@/components/elements/Accordion'), {
    ssr: false, // Enable SSR if the Accordion is important for SEO or initial paint
  });

export default function Faq() {
    return (
        <>
            <section className="faq-area position-relative">
                <div className="faq-bg-shape">
                    <Image
                        src="/assets/img/images/faq_shape01.webp"
                        alt="bg"
                        fill
                        objectFit="cover"
                        quality={100}
                    />
                </div>

                <div className="faq-shape-wrap">
                    <img src="/assets/img/images/faq_shape02.webp" alt="" />
                    <img src="/assets/img/images/faq_shape03.webp" alt="" />
                </div>
                <div className="container">
                    <div className="row align-items-end justify-content-center">
                        <div className="col-lg-6 col-md-9">
                            <div className="faq-img-wrap">
                                <Image
                                    width={435}
                                    height={511}
                                    src="/assets/img/images/faq_image.webp" // Use a relative URL or an absolute URL for an external image
                                    alt="question_image"
                                    layout="responsive"
                                    placeholder="question_image" // Optional blur-up while loading
                                    blurDataURL="/assets/img/images/faq_image.webp" // Provide a low-res version or a data URL for the blur effect
                                />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="faq-content">
                                <div className="section-title mb-30 tg-heading-subheading animation-style2">
                                    <span className="sub-title tg-element-title">Our Service Benifits</span>
                                    <h2 className="title tg-element-title">Unveiling Insights: Your Questions Answered</h2>
                                </div>
                                <p>Your Trusted Partner for High-Quality Pharmaceutical Ingredients.</p>
                                <div className="accordion-wrap">
                                    <DynamicAccordion />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
