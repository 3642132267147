import Image from 'next/image';


export default function About() {
  return (
    <>
      <section
        className="about-area about-bg position-relative"
      >
        <Image
          src="/assets/img/bg/about_bg.webp"
          alt="bg"
          layout="fill"
          objectFit="cover"
          quality={100}
        />
        <div className="container">
          <div className="row align-items-center position-relative">
            <div className="col-lg-5">
              <div className="about-img-wrap">
                <Image
                  src="/assets/img/images/commitment.webp" // Use a relative URL or an absolute URL for an external image
                  alt="about-image"
                  placeholder="about image" // Optional blur-up while loading
                  blurDataURL="/assets/img/images/commitment.webp" // Provide a low-res version or a data URL for the blur effect
                  className="main-img"
                  width={363}
                  height={363}
                />
                <Image
                  src="/assets/img/images/about_img_shape02.webp" // Use a relative URL or an absolute URL for an external image
                  alt="about-image"
                  placeholder="about image" // Optional blur-up while loading
                  blurDataURL="/assets/img/images/about_img_shape02.webp" // Provide a low-res version or a data URL for the blur effect
                  width={130}
                  height={108}
                />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="about-content">
                <div className="section-title mb-25 tg-heading-subheading animation-style2">
                  
                  <h2 className="title tg-element-title">
                    Our Commitment to Excellence
                  </h2>
                </div>
                <p className="p-break">
                  At OHC Medicinal Chemical Trading, we are dedicated to providing pharmaceutical companies with top-quality APIs. With a global network of trusted suppliers and a focus on industry standards, we ensure safe and effective medications. Partner with us for reliable sourcing and superior pharmaceutical ingredients.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
