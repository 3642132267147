import Link from "next/link";
import Slider from "react-slick";
import Image from 'next/image'

const settings = {
  autoplay: true,
  autoplaySpeed: 3000,
  dots: false,
  fade: true,
  arrows: false,
  responsive: [{ breakpoint: 767, settings: { dots: false, arrows: false } }],
};

export default function MainSlider() {
  return (
    <>
      <section className="slider-area">
        <Slider {...settings} className="slider-active">
          <div
            className="single-slider slider-bg"
          >
            <Image
              src="/assets/img/banner/slider_images/slider_03.webp"
              alt="Descriptive text about the background"
              fill
              objectFit="cover"
              objectPosition="center"
              priority // This prop makes the image load with higher priority
              loading="eager"
            />
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="slider-content">
                    <span
                      className="sub-title"
                      data-animation="fadeInUp"
                      data-delay=".2s"
                    >
                      We Are Expert In This Field
                    </span>
                    <h2
                      className="title"
                      data-animation="fadeInUp"
                      data-delay=".4s"
                    >
                      Your gateway to reliable pharmaceutical ingredients
                    </h2>
                    <p data-animation="fadeInUp" data-delay=".6s">
                      Empowering pharma innovations globally with reliable active pharmaceutical ingredients (APIs).
                    </p>
                    <Link prefetch={false}
                      href="/services"
                      className="btn"
                      data-animation="fadeInUp"
                      data-delay=".8s"
                    >
                      Our Services
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="slider-shape">
              <img
                src="/assets/img/banner/banner_shape.webp"
                alt=""
                data-animation="zoomIn"
                data-delay=".8s"
                style={{ "height": "100%" }}
                />
            </div>
          </div>
          <div
            className="single-slider slider-bg"
          >
            <Image
              src="/assets/img/banner/slider_images/slider_02.webp"
              alt="Descriptive text about the background"
              fill
              objectFit="cover"
              objectPosition="center"
            />
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="slider-content">
                    <span
                      className="sub-title"
                      data-animation="fadeInUp"
                      data-delay=".2s"
                    >
                      We Are Expert In This Field
                    </span>
                    <h2
                     className="title"
                     data-animation="fadeInUp"
                     data-delay=".4s"
                    >
                      Driving excellence in APIs trading worldwide
                    </h2>
                    <p data-animation="fadeInUp" data-delay=".6s">
                      Empowering pharma innovations globally with reliable active pharmaceutical ingredients (APIs).
                    </p>
                    <Link prefetch={false}
                      href="/services"
                      className="btn"
                      data-animation="fadeInUp"
                      data-delay=".8s"
                    >
                      Our Services
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="slider-shape">
              <img
                src="/assets/img/banner/banner_shape.webp"
                alt=""
                data-animation="zoomIn"
                data-delay=".8s"
                style={{ "height": "100%" }}
              />
            </div>
          </div>
          <div
            className="single-slider slider-bg"
          >
            <Image
              src="/assets/img/banner/slider_images/slider_01.webp"
              alt="Descriptive text about the background"
              fill
              objectFit="cover"
              objectPosition="center"
            />
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="slider-content">
                    <span
                      className="sub-title"
                      data-animation="fadeInUp"
                      data-delay=".2s"
                    >
                      We Are Expert In This Field
                    </span>
                    <h2
                      className="title"
                      data-animation="fadeInUp"
                      data-delay=".4s"
                    >
                      Driving excellence in APIs trading worldwide
                    </h2>
                    <p data-animation="fadeInUp" data-delay=".6s">
                      Empowering pharma innovations globally with reliable active pharmaceutical ingredients (APIs).
                    </p>
                    <Link prefetch={false}
                      href="/services"
                      className="btn"
                      data-animation="fadeInUp"
                      data-delay=".8s"
                    >
                      Our Services
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="slider-shape">
              <img
                src="/assets/img/banner/banner_shape.webp"
                alt=""
                data-animation="zoomIn"
                data-delay=".8s"
                style={{ "height": "100%" }}
              />
            </div>
          </div>
        </Slider>
      </section>
    </>
  );
}
